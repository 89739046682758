// FIXME #5647
import '../../lib/phone_mask';
import { csrfToken } from '../../utils/form';
import { alertShowDanger, alertShowSuccess } from '../libs/alerts';

$(function (){
    var phoneField = $('#callback_phone');

    function disableSend() {
        $('#callback-send').attr('disabled', true);
    }

    function enableSend() {
        $('#callback-send').attr('disabled', false);
    }

    function validatePhone() {
        if ($('#callback_phone').val() == '') {
            disableSend();
        } else {
            enableSend();
        }
    }

    $('#callback').click(function () {
        phoneField.val('');
        disableSend();
        $('#modal-callback').modal();
    });

    $('#callback-send').click(function () {
        $('#modal-callback').modal('hide');

        $.ajax({
            url: '/feedback/callback',
            type: 'POST',
            dataType: "json",
            data: {
                phone: $('#callback_phone').val(),
                authenticity_token: csrfToken()
            },
            success: function (data) {
                if (data.ok) {
                    alertShowSuccess(
                        'В ближайшее время с вами свяжется наш менеджер');
                } else {
                    alertShowDanger(
                        'Извините, но произошла ошибка и мы не сможем вам перезвонить')
                }
            },
            error: function (qXHR, textStatus, errorThrown) {
              alertShowDanger(
                  'Извините, но произошла ошибка и мы не сможем вам перезвонить')
            }
        });
    });

    phoneField.keyup(function () {
        validatePhone();
    });

    phoneField.on('paste', function () {
        validatePhone();
    });

    phoneField.phoneMask();
});
