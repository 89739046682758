const alertShowSuccess = function(message) {
    const alert = $('.alert-success');
    alert.html(message);
    alert.removeClass('hidden');
};

const alertShowDanger = function(message) {
    const alert = $('.alert-danger');
    alert.html(message);
    alert.removeClass('hidden');
};

export { alertShowSuccess, alertShowDanger };
